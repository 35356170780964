(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-grid/views/grid.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-grid/views/grid.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_groups=_svs.banner_groups||{};
_svs.banner_groups.banner_grid=_svs.banner_groups.banner_grid||{};
_svs.banner_groups.banner_grid.templates=_svs.banner_groups.banner_grid.templates||{};
svs.banner_groups.banner_grid.templates.grid = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  class=\"widget-banner-grid bg-white f-content margin-xs-top-2 margin-xs-bottom-3 margin-md-top-4 margin-md-bottom-4\"\n  data-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"widgetId") || (depth0 != null ? lookupProperty(depth0,"widgetId") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"widgetId","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":23}}}) : helper)))
    + "\"\n>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":10,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"subTitle") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":13,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"layout") : depth0),"===","portrait",{"name":"compare","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":45,"column":14}}})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <h2 class=\"h1 f-section-title f-center "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"subTitle") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":102}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":15}}}) : helper)))
    + "</h2>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "margin-bottom-1";
},"5":function(container,depth0,helpers,partials,data) {
    return "margin-bottom-2";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"f-summary f-center margin-bottom-2\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"subTitle") || (depth0 != null ? lookupProperty(depth0,"subTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subTitle","hash":{},"data":data,"loc":{"start":{"line":12,"column":50},"end":{"line":12,"column":62}}}) : helper)))
    + "</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"banner-grid-carousel\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-carousel-carousel"),depth0,{"name":"components-lb_ui-carousel-carousel","hash":{"isMobile":(depth0 != null ? lookupProperty(depth0,"_isMobile") : depth0),"children":(depth0 != null ? lookupProperty(depth0,"childElements") : depth0)},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"childElements") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":28,"column":17}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-carousel-carousel_item"),depth0,{"name":"components-lb_ui-carousel-carousel_item","fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"aspect-ratio-box\">\n              <div aria-disabled=\"false\" class=\"paper aspect-ratio-content elevation-auto-raise js-clickable clickable-magnetic elevation-100 paper-sport\">\n                <div class=\"fullsize\">\n                  "
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "\n                </div>\n              </div>\n            </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_isMobile") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":44,"column":11}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"products-carousel")||(depth0 && lookupProperty(depth0,"products-carousel"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"carousel",{"name":"products-carousel","hash":{"clickSelector":"a","layout":(depth0 != null ? lookupProperty(depth0,"layout") : depth0)},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":6},"end":{"line":37,"column":26}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"childElements") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":6},"end":{"line":36,"column":15}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = (lookupProperty(helpers,"products-carousel")||(depth0 && lookupProperty(depth0,"products-carousel"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"item",{"name":"products-carousel","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":6},"end":{"line":35,"column":67}}})) != null ? stack1 : "")
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"products-grid-flexible")||(depth0 && lookupProperty(depth0,"products-grid-flexible"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"grid",{"name":"products-grid-flexible","hash":{"layout":(depth0 != null ? lookupProperty(depth0,"layout") : depth0)},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":4},"end":{"line":43,"column":31}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"childElements") : depth0),{"name":"each","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":6},"end":{"line":42,"column":15}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = (lookupProperty(helpers,"products-grid-flexible")||(depth0 && lookupProperty(depth0,"products-grid-flexible"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"item",{"name":"products-grid-flexible","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":6},"end":{"line":41,"column":77}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_hasEnoughChildren") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":47,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-banner_groups-banner_grid-grid'] = svs.banner_groups.banner_grid.templates.grid;
})(svs, Handlebars);


 })(window);